import React, { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';

export const EmailForm: React.FC = () => {
 const [subject, setSubject] = useState('');
 const [body, setBody] = useState('');
 const destinationEmail = "correo@contalia.com";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Implement email sending logic here
    const mailtoLink = `mailto:${destinationEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, '_blank');
    // console.log(subject.trim().length);
    // console.log(body.trim().length);
  };

 return (
   <div>
   <h3>Contacto</h3>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
      <TextField
        label="Asunto"
        variant="outlined"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
        </Grid>
        <Grid item xs={12}>
      <TextField
        style={{
          width: "50rem"
        }}
        label="Cuerpo"
        variant="outlined"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        multiline
        rows={4}
      />
        </Grid>
        <Grid item xs={12}>
        <Button type="submit" variant="contained" disabled={subject.trim().length <= 0 || body.trim().length <= 0}>
        Enviar Email
      </Button>
        </Grid>
      </Grid>
    </form>
   </div>
 );
};

