import "./App.css";

import { EmailForm } from "./components/email_form/email_form";

function App() {
  const companyName: string = "Contalia";
  const companySubtitle: string = "Administración de Empresas y Fincas S.L.U.";
  const companyAddress: string = "Avenida de la Costa 114 * 33204 * Gijón";
  const companyPhone1: string = "(+34) 985374254";
  const companyPhone2: string = "(+34) 686189312";
  const companyEmail: string = "correo@contalia.com";

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="company-name">{companyName}</h1>
        <h2 className="company-name">{companySubtitle}</h2>
        <p className="company-address">{companyAddress}</p>
      </header>
      <hr />
      <EmailForm />
      <hr />
      <footer>
        <p>Teléfono: {companyPhone1}</p>
        <p>Móvil: {companyPhone2}</p>
        <p>
          Email: <a href={companyEmail}>{companyEmail}</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
